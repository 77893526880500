import React from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import './Footer.sass';
import { Link } from 'react-router-dom';

const logo = require('../../common/svg/logo.svg').default;

interface IFooter {
  extraClass?: string;
  text: string;
  entity: string;
}

function Footer({ extraClass, text, entity }: IFooter) {
  const cnFooter = cn('Footer');

  return (
    <footer className={classnames(cnFooter(), extraClass)}>
      <img className={classnames(cnFooter('Image'), 'Image')} src={logo} alt="VideoFun" />
      <div className={classnames(cnFooter('LinksWrapper'))}>
        <Link
          to="/user/conditions"
          className={cnFooter('Link')}
          onClick={() => { window.scrollTo(0, 0); }}
        >
          Пользовательское соглашение
        </Link>
        <Link
          to="/privacypolicy"
          className={cnFooter('Link')}
          onClick={() => { window.scrollTo(0, 0); }}
        >
          Политика обработки персональных данных
        </Link>
      </div>
      {/* eslint-disable react/no-danger */}
      {text ? <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: text }} /> : null}
      <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: entity }} />
    </footer>
  );
}

Footer.defaultProps = {
  extraClass: '',
};

export default Footer;
